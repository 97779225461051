import React, { useEffect, useState } from 'react';
import { useDataPointValueV2 } from '../../layout/hooks/v2/useDataPointValueV2';
import { TreeItem } from '@weave-mui/tree-view';
import {
  AdvancedFactorInputTracker,
  extractInputIdentifier,
  generateInputIdentifier,
  InputWithStatus,
  triggerAddTaskInputs,
  triggerRemoveTaskInputs,
} from '../types/AdvancedFactorInputTracker';
import { FactorValue } from '../../types/metrics';
import { BASE_RUN_FACTOR_NAME } from '../../types/jobs';
import { useSignalEffect } from '@preact/signals-react';

interface SimulationInputItemProps {
  inputTracker: AdvancedFactorInputTracker;
  isSimulatedView: boolean;
}

const SimulationInputItem: React.FC<SimulationInputItemProps> = ({
  inputTracker,
  isSimulatedView,
}) => {
  const {
    data: dataPointValue,
    isLoading,
    isSuccess,
    isError,
  } = useDataPointValueV2(inputTracker.advancedfactorId);
  const [factorValuesWithBaseRun, setfactorValuesWithBaseRun] = useState<FactorValue[]>(null);
  const [displayInputs, setDisplayInputs] = useState<InputWithStatus[]>([]);

  useSignalEffect(() => {
    // parse ids to add and update the inputs
    // ensure setDisplayInputs with the adjusted inputs
    // do not change the original inputTracker object refence
    // just remove inputs from the availableInputs and add to the pending
    const inputsToAdd = triggerAddTaskInputs.value.filter((x) =>
      x.startsWith(inputTracker.simulationId)
    );

    inputsToAdd.forEach((id) => {
      console.log(`triggerAddTaskInputs for ${inputTracker.displayName} id:`, id);
    });

    const inputsToRemove = triggerRemoveTaskInputs.value.filter((x) =>
      x.startsWith(inputTracker.simulationId)
    );

    inputsToRemove.forEach((id) => {
      console.log(`triggerRemoveTaskInputs for ${inputTracker.displayName} id:`, id);
    });

    if (inputsToAdd.length || inputsToRemove.length) {
      parseInputs(inputsToAdd, inputsToRemove);
    }
  });

  useEffect(() => {
    if (isSuccess && dataPointValue && !isLoading) {
      parseInputs();
    }
  }, [isSuccess, isLoading]);

  const parseInputs = (idsToAdd: string[] = [], idsToRemove: string[] = []) => {
    const availableFactorValues = dataPointValue.value as FactorValue[]; // this includes the base run input and is labelled as such

    const actionedInputs = [
      ...inputTracker.simulatedInputs.map(
        (input) => ({ input, status: 'Complete' } as InputWithStatus)
      ),
      ...inputTracker.inProgressInputs.map(
        (input) => ({ input, status: 'In Progress' } as InputWithStatus)
      ),
      ...inputTracker.pendingInputs.map(
        (input) => ({ input, status: 'Pending' } as InputWithStatus)
      ),
      ...inputTracker.failedInputs.map((input) => ({ input, status: 'Failed' } as InputWithStatus)),
    ];

    let availableInputs = availableFactorValues
      .filter(
        (x) =>
          !actionedInputs.some(
            (actionedInput) =>
              actionedInput.input.imperialStandardValue.value === x.imperialStandardValue.value &&
              actionedInput.input.industryStandardValue.value === x.industryStandardValue.value
          )
      )
      .map(({ industryStandardValue, imperialStandardValue }) => {
        return {
          input: {
            parameterId: inputTracker.simulationId,
            imperialStandardValue,
            industryStandardValue,
            type: inputTracker.type,
          },
          status: 'Not Run',
        } as InputWithStatus;
      });

    if (idsToAdd.length) {
      idsToAdd.forEach((id) => {
        const { simulationId, value } = extractInputIdentifier(id);
        const matchedInput = availableInputs.find(
          (input) =>
            input.input.parameterId === simulationId &&
            (input.input.imperialStandardValue.value === value ||
              input.input.industryStandardValue.value === value)
        );
        if (matchedInput) {
          inputTracker.pendingInputs.push(matchedInput.input);
          actionedInputs.push({ ...matchedInput, status: 'Pending' });
          availableInputs = availableInputs.filter((input) => input !== matchedInput);
        }
      });
    }

if (idsToRemove.length) {
  idsToRemove.forEach((id) => {
    const { simulationId, value } = extractInputIdentifier(id);
    const matchedInput = actionedInputs.find(
      (input) =>
        input.input.parameterId === simulationId &&
        (input.input.imperialStandardValue.value === value ||
          input.input.industryStandardValue.value === value)
    );
    if (matchedInput) {
      // Remove from pendingInputs
      inputTracker.pendingInputs = inputTracker.pendingInputs.filter(
        (input) =>
          input.imperialStandardValue.value !== matchedInput.input.imperialStandardValue.value ||
          input.industryStandardValue.value !== matchedInput.input.industryStandardValue.value
      );

      // Remove from simulatedInputs
      inputTracker.simulatedInputs = inputTracker.simulatedInputs.filter(
        (input) =>
          input.imperialStandardValue.value !== matchedInput.input.imperialStandardValue.value ||
          input.industryStandardValue.value !== matchedInput.input.industryStandardValue.value
      );

      // Add back to availableInputs
      availableInputs.push({
        input: matchedInput.input,
        status: 'Not Run',
      });
    }
  });
}

    // make sure added to the tracking object
    inputTracker.availableInputs = availableInputs.map((input) => input.input);

    setDisplayInputs(isSimulatedView ? actionedInputs : availableInputs);
    setfactorValuesWithBaseRun(availableFactorValues);
  };

  if (isLoading) {
    return <TreeItem nodeId={inputTracker.advancedfactorId} label="Loading..." />;
  }

  if (isError) {
    return <TreeItem nodeId={inputTracker.advancedfactorId} label="Error loading value" />;
  }

  if (!dataPointValue) {
    return <TreeItem nodeId={inputTracker.advancedfactorId} label="No value available" />;
  }

  const { unitSymbol } = dataPointValue;

  return (
    (factorValuesWithBaseRun && displayInputs && (
      <TreeItem
        nodeId={inputTracker.advancedfactorId}
        label={<span style={{ paddingLeft: '16px' }}>{inputTracker.displayName}</span>}
      >
        {displayInputs.map(({ input, status }) => {
          const matchedFactorValue = factorValuesWithBaseRun?.find(
            (factorValue) =>
              factorValue.industryStandardValue?.value === input.industryStandardValue?.value &&
              factorValue.imperialStandardValue?.value === input.imperialStandardValue?.value
          );

          const keyId = generateInputIdentifier(input); //note keys for the treeitem cannot be complex objects, so we need to use a string
          const isBaseRun = matchedFactorValue.name === BASE_RUN_FACTOR_NAME;
          return (
            <TreeItem
              key={keyId}
              nodeId={keyId}
              disabled={isBaseRun}
              label={
                <div
                  style={{
                    display: 'flex',
                    padding: '0 16px',
                  }}
                >
                  <span style={{ flex: 1 }}>{matchedFactorValue?.name}</span>
                  <span style={{ flex: 1 }}>
                    {matchedFactorValue.imperialStandardValue.value !== undefined
                      ? `${matchedFactorValue.imperialStandardValue.value}${unitSymbol || ''}`
                      : 'N/A'}
                  </span>
                  {isSimulatedView && <span style={{ flex: 1 }}>{status}</span>}
                </div>
              }
            />
          );
        })}
      </TreeItem>
    )) || <></>
  );
};

export default SimulationInputItem;
