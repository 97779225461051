import { TaskSingleInput } from '../../types/tasks';
import { signal } from '@preact/signals-react';

export type InputStatus = 'In Progress' | 'Pending' | 'Complete' | 'Failed' | 'Not Run';

export type InputWithStatus = {
  input: TaskSingleInput;
  status: InputStatus;
};

export type AdvancedFactorInputTracker = {
  advancedfactorId: string;
  simulationId: string;
  displayName: string;
  availableInputs: TaskSingleInput[]; //the list of all available inputs for a given factor
  simulatedInputs: TaskSingleInput[]; //the list of inputs that are simulated for a given factor
  inProgressInputs: TaskSingleInput[]; //the list of inputs that are in progress for a given factor
  failedInputs: TaskSingleInput[]; //the list of inputs that are failed for a given factor
  pendingInputs: TaskSingleInput[]; //the list of inputs that are pending for a given factor
  type: string; // simualtion factor type
};

export const generateInputIdentifier = (taskInput: TaskSingleInput): string => {
  const { parameterId, imperialStandardValue, industryStandardValue } = taskInput;
  return `${parameterId}-value-${
    industryStandardValue.value ?? imperialStandardValue.value ?? 'default'
  }`;
};

export const extractInputIdentifier = (id: string): { simulationId: string; value: number | string } => {
  if (id.includes('-value-')) {
    const [simulationId, value] = id.split('-value-');
    return {
      simulationId,
      value: isNaN(Number(value)) ? value : Number(value),
    };
  }
};

export const isGeneratedInputIdentifier = (id: string): boolean => /-value-/.test(id);

export const triggerAddTaskInputs = signal<string[]>([]);

export const triggerRemoveTaskInputs = signal<string[]>([]);
