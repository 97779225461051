import React, { useCallback, useEffect, useState } from 'react';
import { closeModalReasons } from '../../utils/constants';
import WeaveModalDialog from '../../shared/WeaveModalDialog';
import { SimulationsModalStyles } from '../styles/simulations.stylesheet';
import i18n from '../../i18n';
import SimulationsManage from './SimulationsManage';
import { useGetImperialSystem } from '../../layout/hooks/useCurrentProjectData';

interface SimulationsModalWrapperProps {
  isOpen: boolean;
  setModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

const SimulationsModalWrapper: React.FC<SimulationsModalWrapperProps> = ({
  isOpen,
  setModalVisibility,
}) => {
  const closeModal = useCallback(() => {
    setModalVisibility(false);
  }, []);

  useEffect(() => {
    const handleCloseOnBackButton = () => {
      if (isOpen) {
        closeModal();
      }
      window.history.pushState({ modalClosed: true }, '');
    };

    window.addEventListener('popstate', handleCloseOnBackButton);

    return () => {
      window.removeEventListener('popstate', handleCloseOnBackButton);
    };
  }, [closeModal, isOpen]);

  const modalHeader = i18n.t("simulationsManage.modalManage.modalHeader");

  return (
    <WeaveModalDialog
      sx={{ ...SimulationsModalStyles }}
      open={isOpen}
      header={modalHeader}
      onClose={closeModal}
      disablePortal
      disableEscapeKeyDown
    >
      <SimulationsManage onCloseModal={closeModal} />
    </WeaveModalDialog>
  );
};

export default SimulationsModalWrapper;
