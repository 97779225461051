import React from 'react';
import TreeView from '@weave-mui/tree-view';
import Typography from '@weave-mui/typography';
import Box from '@weave-mui/box';
import {
  AdvancedFactorInputTracker,
  isGeneratedInputIdentifier,
} from '../types/AdvancedFactorInputTracker';
import SimulationInputItem from './SimulationInputItem';
import { Signal } from '@preact/signals-core';
import { useSignals } from '@preact/signals-react/runtime';

interface SimulationTreeViewProps {
  title: string;
  factors: AdvancedFactorInputTracker[];
  setValuesSignal: Signal<string[]>;
  isSimulatedView?: boolean; // Determines if this is for simulated factors
}

const SimulationTreeView: React.FC<SimulationTreeViewProps> = ({
  title,
  factors,
  setValuesSignal,
  isSimulatedView = false,
}) => {
  useSignals();
  const renderTreeItems = () =>
    factors.map((factor) => {
      return <SimulationInputItem inputTracker={factor} isSimulatedView={isSimulatedView} />;
    });

  return (
    <Box
      sx={{
        flex: 1,
        padding: '1rem',
        borderRadius: '8px',
        overflowY: 'auto',
      }}
    >
      <Typography variant="body1" sx={{ marginBottom: '1rem', textAlign: 'left' }}>
        {title}
      </Typography>
      {/* Header for TreeView */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0.5rem 1rem',
          borderBottom: '1px solid #ccc',
          fontWeight: 'bold',
        }}
      >
        <span>Name</span>
        <span>Value</span>
        {isSimulatedView && <span>Status</span>}
      </Box>
      <TreeView
        selected={setValuesSignal.value}
        onNodeSelect={(_, selected) => {
          if (isGeneratedInputIdentifier(selected)) {
            // filter out top level nodes
            setValuesSignal.value = setValuesSignal.value.includes(selected)
              ? setValuesSignal.value.filter((s) => s !== selected)
              : [...setValuesSignal.value, selected];
          }
        }}
        // defaultExpanded={
          // factors.length > 0
          //   ? [
          //       ...factors.map((factor) => factor.advancedfactorId),
          //       ...factors.flatMap((factor) =>
          //         (isSimulatedView ? factor.simulatedInputs : factor.availableInputs).map(
          //           (input: any) =>
          //             `${factor.advancedfactorId}-input-${input.parameterId}-value-${
          //               input.imperialStandardValue.value ??
          //               input.industryStandardValue?.value ??
          //               'default'
          //             }`
          //         )
          //       ),
          //     ]
          //   : []
        // }
      >
        {renderTreeItems()}
      </TreeView>
    </Box>
  );
};

export default SimulationTreeView;
