import React, { useEffect, useState } from 'react';
import Box from '@weave-mui/box';
import Button from '@weave-mui/button';
import Typography from '@weave-mui/typography';
import i18n from '../../i18n';
import ActionButtons from '../shared/ActionButtons';
import { useDataPointsV2 } from '../../layout/hooks/v2/useDataPointsV2';
import { getAdvancedFactorDefinitionAndValuesFromSimulationFactorId } from '../../layout/utils/dataPointsResultsUtils';
import { AdvancedFactorDefinition } from '../../types/metrics';
import { TaskSingleInput } from '../../types/tasks';
import {
  AdvancedFactorInputTracker,
  triggerAddTaskInputs,
  triggerRemoveTaskInputs,
} from '../types/AdvancedFactorInputTracker';
import { useGetTasksForModelIdQuery } from '../../state/api/task-service-api';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import SimulationTreeView from './SimulationTreeView';
import { useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

interface SimulationsModalProps {
  onCloseModal: () => void;
}
const SimulationManage: React.FC<SimulationsModalProps> = ({ onCloseModal }) => {
  useSignals();
  const { data: dataPointsResult } = useDataPointsV2();
  let currentModelId = useSelector((state: RootState) => state.applicationDataState.currentModelId);
  const split = currentModelId?.split('model:');
  const strippedModelId = split?.length > 1 ? split[1] : currentModelId;
  const [inputTrackers, setInputTrackers] = useState<AdvancedFactorInputTracker[]>([]);
  const inputsToAdd = useSignal<string[]>([]);
  const inputsToRemove = useSignal<string[]>([]);

  const { data: tasks } = useGetTasksForModelIdQuery(strippedModelId, {
    skip: !strippedModelId,
  });

  useEffect(() => {
    triggerAddTaskInputs.value = [];
    triggerRemoveTaskInputs.value = [];
  }, []);

  useEffect(() => {
    if (dataPointsResult && dataPointsResult.definitions.advancedFactors) {
      const advancedFactors = dataPointsResult.definitions.advancedFactors;
      const simulatedRuns = dataPointsResult.analysisRuns;
      // Get unique simulated task inputs
      const getUniqueSimulatedTaskInputs = (
        definition: AdvancedFactorDefinition,
        inputs: TaskSingleInput[]
      ) => {
        return inputs
          .filter((input) => input.parameterId === definition.simulationFactor)
          .reduce((acc: TaskSingleInput[], value: TaskSingleInput) => {
            if (
              !acc.find(
                (input) =>
                  input.imperialStandardValue?.value === value.imperialStandardValue?.value &&
                  input.industryStandardValue?.value === value.industryStandardValue?.value
              )
            ) {
              acc.push(value);
            }
            return acc;
          }, []);
      };

      const getAllFailedInputs = (definition: AdvancedFactorDefinition) => {
        // Filter runs that match the simulation factor and have failed status
        const failedInputs = simulatedRuns
          ?.filter((run) =>
            run.inputs.some(
              (input: TaskSingleInput) =>
                input.parameterId === definition.simulationFactor && run.info?.success === false
            )
          )
          .flatMap((run) =>
            run.inputs.filter(
              (input: TaskSingleInput) =>
                input.parameterId === definition.simulationFactor && run.info?.success === false
            )
          );

        // get unique  failed inputs based on their values
        return failedInputs?.reduce((acc: TaskSingleInput[], value: TaskSingleInput) => {
          if (
            !acc.find(
              (input) =>
                input.imperialStandardValue?.value === value.imperialStandardValue?.value &&
                input.industryStandardValue?.value === value.industryStandardValue?.value
            )
          ) {
            acc.push(value);
          }
          return acc;
        }, []);
      };

      const inputTracker: AdvancedFactorInputTracker[] = advancedFactors.map((factor) => {
        const simulationFactorDefinition =
          getAdvancedFactorDefinitionAndValuesFromSimulationFactorId(
            factor.simulationFactor,
            dataPointsResult
          );

        return {
          advancedfactorId: factor.id,
          simulationId: factor.simulationFactor,
          displayName: factor.displayName,
          availableInputs: [], // assign in component
          simulatedInputs: getUniqueSimulatedTaskInputs(
            factor,
            simulatedRuns.flatMap((run) => run.inputs)
          ),
          inProgressInputs: getUniqueSimulatedTaskInputs(
            factor,
            tasks.flatMap((task) => task.request.payload.inputs)
          ),

          failedInputs: getAllFailedInputs(factor),
          pendingInputs: [], // Initialize pendingInputs as empty
          type: simulationFactorDefinition.simulationFactorDefinition.simulationParameter.type,
        };
      });

      setInputTrackers(inputTracker);
    }
  }, [dataPointsResult, tasks]);

  const handleAdd = () => {
    // send signal to listening components to parse the inputs
    // and add them to the AdvancedFactorInputTrackers
    triggerAddTaskInputs.value = [...inputsToAdd.value];
    inputsToAdd.value = [];
  };

  const handleRemove = () => {
    // send signal to listening components to parse the inputs
    // and remove them from the AdvancedFactorInputTrackers
    triggerRemoveTaskInputs.value = [...inputsToRemove.value];
    inputsToRemove.value = [];
  };

  const handleSimulate = () => {
    console.log(
      'Ready to simulate with inputs:',
      inputTrackers.flatMap((tracker) => tracker.pendingInputs)
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '1rem',
        gap: '1rem',
        width: '100%',
      }}
    >
      {/* Main content row */}
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', flexGrow: 1, width: '100%' }}>
        {/* Factors not simulated */}
        <SimulationTreeView
          title={i18n.t('simulationsManage.modalManage.factorsNotSimulated')}
          factors={inputTrackers}
          setValuesSignal={inputsToAdd}
          isSimulatedView={false}
        />

        {/* Buttons */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
            width: '75px',
          }}
        >
          <Button
            variant="contained"
            onClick={handleAdd}
            disabled={inputsToAdd.value.length === 0}
            fullWidth={true}
            sx={{ height: '40px', whiteSpace: 'nowrap' }}
          >
            {i18n.t('simulationsManage.button.add')}
          </Button>
          <Button
            variant="contained"
            onClick={handleRemove}
            disabled={inputsToRemove.value.length === 0}
            fullWidth={true}
            sx={{ height: '40px', whiteSpace: 'nowrap' }}
          >
            {i18n.t('simulationsManage.button.remove')}
          </Button>
        </Box>

        {/* Factors simulating or simulated */}
        <SimulationTreeView
          title={i18n.t('simulationsManage.modalManage.factorsSimulated')}
          factors={inputTrackers}
          setValuesSignal={inputsToRemove}
          isSimulatedView={true}
        />
      </Box>

      <Typography variant="h6" sx={{ textAlign: 'left', marginTop: '1rem' }}>
        {i18n.t('simulationsManage.modalManage.totalSimulationsResultsForThisModel')}{' '}
        {inputTrackers.length}
      </Typography>
      <Box sx={{ alignSelf: 'end', display: 'flex', gap: '1rem' }}>
        <ActionButtons onCloseModal={onCloseModal} onSimulate={handleSimulate} />
      </Box>
    </Box>
  );
};

export default SimulationManage;
