import i18n from "../../../i18n";
import { ParametersTypesEnum } from "../../../types/metrics";

export interface NavigationOption {
  title: string;
  descriptionTitle: string;
  descriptionBody: string;
  modalType: ParametersTypesEnum;
}

const navLinks: NavigationOption[] = [
  {
    title: i18n.t('analysis.dataPoints.dropdownValues.factors'),
    descriptionTitle: i18n.t('analysis.dataPoints.dropdownValues.factors'),
    descriptionBody: i18n.t('analysis.dataPoints.dropdownValues.descriptionFactors'),
    modalType: ParametersTypesEnum.FACTORS,
  },
  {
    title: i18n.t('analysis.dataPoints.dropdownValues.metrics'),
    descriptionTitle: i18n.t('analysis.dataPoints.dropdownValues.metrics'),
    descriptionBody: i18n.t('analysis.dataPoints.dropdownValues.descriptionMetrics'),
    modalType: ParametersTypesEnum.METRICS,
  },
  {
    title: i18n.t('analysis.dataPoints.dropdownValues.benchmarks'),
    descriptionTitle: i18n.t('analysis.dataPoints.dropdownValues.benchmarks'),
    descriptionBody: i18n.t('analysis.dataPoints.dropdownValues.descriptionBenchmarks'),
    modalType: ParametersTypesEnum.BENCHMARKS,
  },
  {
    title: i18n.t('analysis.dataPoints.dropdownValues.simulations'),
    descriptionTitle: i18n.t('analysis.dataPoints.dropdownValues.simulations'),
    descriptionBody: i18n.t('analysis.dataPoints.dropdownValues.descriptionSimulations'),
    modalType: ParametersTypesEnum.SIMULATIONS,
  },
];

export default navLinks;
