import BenchmarksIcon from "../components/icons/Benchmarks";
import FactorsIcon from "../components/icons/Factors";
import MetricsIcon from "../components/icons/Metrics";
import BasicFactorsIcon from "../components/icons/BasicFactors";
import AdvancedFactorsIcon from "../components/icons/AdvancedFactors";
import DataPointsIconsEnum from "./iconEnum";
import SimulationsIcon from "../components/icons/Factors"; // temp until we get the correct icon

export const iconMap: Record<DataPointsIconsEnum, React.FC> = {
  [DataPointsIconsEnum.Metrics]: MetricsIcon,
  [DataPointsIconsEnum.Benchmarks]: BenchmarksIcon,
  [DataPointsIconsEnum.Factors]: FactorsIcon,
  [DataPointsIconsEnum.BasicFactors]: BasicFactorsIcon,
  [DataPointsIconsEnum.AdvancedFactors]: AdvancedFactorsIcon,
  [DataPointsIconsEnum.Simulations]: SimulationsIcon
};
