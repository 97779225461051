export const textAreaStyleDescription = {
  width: '100%',
  '& .MuiInputBase-input': {
    height: '4rem !important',
    minHeight: '4rem !important',
    overflow: 'auto !important',
  },
  '& .MuiErrorIconWeave-root': {
    display: 'none',
  },
};

export const textAreaStyleTextField = {
  width: '100%',
  '& .MuiErrorIconWeave-root': {
    display: 'none',
  },
};

export const SimulationsModalStyles = {
  zIndex: 10000,
  '& .MuiModalWeave-box': {
    width: '80%',
    height: '95%',
    maxHeight: 'calc(100vh - 20px - 20px);',
  },
  '& .MuiModalWeave-content': {
    maxHeight: 'initial',
    height: '100%',
  },
  '& .MuiModalWeave-content-no-actions': {
    padding: '0px',
  },
  '& .MuiModalWeave-content .MuiModalWeave-child': {
    padding: '0px',
    height: '100%',
  },
  '& .MuiModalWeave-content-text': {
    overflow: 'inherit',
    margin: '0px',
    padding: '0px',
  },
  '& .MuiModalWeave-wrapper': {
    justifyContent: 'space-between',
  },
};
