enum DataPointsIconsEnum {
  Metrics = 'Metrics',
  Benchmarks = 'Benchmarks',
  Factors = 'Factors',
  AdvancedFactors = 'AdvancedFactors',
  BasicFactors = 'BasicFactors',
  Simulations = 'Simulations',
};

export default DataPointsIconsEnum;
