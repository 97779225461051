import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LayoutTab, LayoutTabsConfig } from '../types/layout';
import { dashboardToLayoutTabsConfig } from './utils/layout-setup';
import { LayoutTabs } from './Tabs/LayoutTabs';
import { useGetDashboardsQuery } from '../state/api/dashboard-api';
import { LayoutContextProvider } from './LayoutContext';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@weave-mui/box';
import SideNavigation from '../shared/SideNavigation/SideNavigation';
import DataPointsModalWrapper from '../dataPoints/components/shared/DataPointsModal/DataPointsModalWrapper';
import { ParametersTypesEnum } from '../types/metrics';
import {useDispatch, useSelector} from "react-redux";
import {taskServiceApi, useLazyGetChannelForModelQuery} from "../state/api/task-service-api";
import {simulationsTagType} from "../state/api/api-constants";
import {convertModelUrnToModelId} from "../utils/format";
import {ApplicationDataState} from "../state/slice/application-data-slice";
import { useCurrentAnalysisRunSelectorV2 } from './hooks/v2/useAnalysisRunV2';
import {AcpEventServiceManager} from "../notifications/acpEventServiceManager";
import SimulationsModalWrapper from '../simulations/components/SimulationsModalWrapper';
import { useIsV2APIEnabled } from '../shared/application/application-hooks';

export const LayoutContainer: React.FC = () => {
  useCurrentAnalysisRunSelectorV2();
  const navigate = useNavigate();
  const routeParams = useParams();
  const dispatch = useDispatch();
  const [trigger, { data, error, isLoading }] = useLazyGetChannelForModelQuery();
  const { data: isV2Enabled} = useIsV2APIEnabled();

  const [metricsModalOpen, setMetricsModalOpen] = useState<boolean>(false);
  const [simulationModalOpen, setSimulationModalOpen] = useState<boolean>(false);
  const [parametersModalType, setParametersModalType] = useState<ParametersTypesEnum>(ParametersTypesEnum.FACTORS);

  const [layoutTabsConfig, setLayoutTabsConfig] = useState<LayoutTabsConfig>();
  const [initialActiveTab, setInitialActiveTab] = useState<LayoutTab | null>();
  // todo: resiliency handle failures and notify user
  //Clear the task info cache so that we get the latest running tasks
  const { data: dashboards } = useGetDashboardsQuery();
  const modelUrn = useSelector(
    (state: { applicationDataState: ApplicationDataState }) =>
      state.applicationDataState.currentModelId
  );
  useEffect(() => {
    const modelId = convertModelUrnToModelId(modelUrn);
    if(!modelId || !isV2Enabled) {
      return;
    }

    trigger(modelId).then((response) => {
      AcpEventServiceManager.instance.subscribeToEventsForModelId(modelId);
      dispatch(taskServiceApi.util.invalidateTags([{type: simulationsTagType, id: modelId}])); //load the latest runs to create the subscription
    })
      .catch((err) => {
        console.error(err);
      });
  }, [modelUrn, isV2Enabled]);

  // temoporary mock data
  // const [dashboards] = useState<StandardDashboard[]>(getMockStandardDashboards());
  useEffect(() => {
    if (dashboards?.length) {
      const tabsConfig = dashboardToLayoutTabsConfig(dashboards);
      setLayoutTabsConfig(tabsConfig);

      let tabToBeUsedAsInitial = null;
      const tabFromPath = routeParams.dashboardId && tabsConfig.tabs.find(( {value} ) => value === routeParams.dashboardId);
      if(tabFromPath) {
        tabToBeUsedAsInitial = tabFromPath;
      }

      setInitialActiveTab( tabToBeUsedAsInitial || tabsConfig.tabs[0] );
    }
  }, [dashboards, navigate, routeParams]);

  const optionClickHandler = useCallback((optionModalType: ParametersTypesEnum) => {
    if (optionModalType === ParametersTypesEnum.SIMULATIONS) {
      setSimulationModalOpen(true);
      return;
    }
    setParametersModalType(optionModalType);
    setMetricsModalOpen(true);
  }, []);


  return (
    <>
      <SimulationsModalWrapper
        isOpen={simulationModalOpen}
        setModalVisibility={setSimulationModalOpen}
      />
      <DataPointsModalWrapper
        isOpen={metricsModalOpen}
        modalType={parametersModalType}
        setModalVisibility={setMetricsModalOpen}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
        }}
      >
        {initialActiveTab && <SideNavigation onOptionClick={optionClickHandler} />}
        {layoutTabsConfig && initialActiveTab && (
          <LayoutContextProvider>
            <LayoutTabs {...layoutTabsConfig} initialActiveTab={initialActiveTab}></LayoutTabs>
          </LayoutContextProvider>
        )}
      </Box>
    </>
  );
};
