import Button, { buttonVariants } from '@weave-mui/button';
import React, { useCallback } from 'react';
import i18n from '../../i18n';
import { footerButtonStyle } from '../../layout/LayoutGrid.stylesheet';

interface ActionButtonsProps {
  onCloseModal: () => void;
  onSimulate: () => void; // Add onSimulate callback
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  onCloseModal,
  onSimulate,
}) => {
  const closeHandler = useCallback(() => onCloseModal(), [onCloseModal]);
  const simulateHandler = useCallback(() => onSimulate(), [onSimulate]);

  return (
    <div style={{ display: 'flex', gap: '1rem' }}>
      <Button
        sx={{ ...footerButtonStyle }}
        className="noMargin"
        onClick={closeHandler}
        type="button"
        variant={buttonVariants.CONTAINED}
      >
        {i18n.t('simulationsManage.button.close')}
      </Button>
      <Button
        sx={{ ...footerButtonStyle }}
        className="noMargin"
        onClick={simulateHandler}
        type="button"
        variant={buttonVariants.CONTAINED}
      >
        {i18n.t('simulationsManage.button.simulate')}
      </Button>
    </div>
  );
};

export default ActionButtons;
